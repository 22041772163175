import React from 'react';

interface ICoffeeIntensityScaleProps {
    intensity: number | undefined;
    maxIntensity: string;
}

const CoffeeIntensityScale: React.FC<ICoffeeIntensityScaleProps> = ({ intensity, maxIntensity }) => {
    if (!intensity && !maxIntensity) {
        return null;
    }
    if (typeof maxIntensity === 'string' && maxIntensity.includes('Flavoured')) {
        return (
            <div className='coffee-intensity-scale'>
                <span className='coffee-intensity-label'>Flavoured</span>
            </div>
        );
    }
    const totalBars = parseInt(maxIntensity, 10);
    const intensityValue = intensity ?? 0;
    const minIntensity = Math.min(intensityValue, totalBars);
    return (
        <div className='coffee-intensity-scale'>
            {Array.from({ length: totalBars }).map((_, index) => {
                const barIndex = index + 1;
                const isHighlighted = barIndex <= minIntensity;
                const barColor = isHighlighted ? '#1E0D00' : '#D9D9D9';

                return <div key={barIndex} className='coffee-intensity-bar' style={{ backgroundColor: barColor }} />;
            })}
            <span className='coffee-intensity-label'>{intensity}</span>
        </div>
    );
};
export default CoffeeIntensityScale;
