import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';
import { AttributeValue, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import {
    FORMAT_ATTRIBUTE_NAME,
    INTENCITY_ATTRIBUTE_NAME,
    SHORT_ATTRIBUTE_NAME,
    CUPSIZE_ATTRIBUTE_NAME,
    MAXINTENSITY_ATTRIBUTE_NAME,
    ISCAPSULEREQUIRE_ATTRIBUTE_NAME,
    ISINTENSITYREQUIRE_ATTRIBUTE_NAME
} from '../constants';

export const getAttributeByName = (attributes: AttributeValue[], name: string): AttributeValue | undefined => {
    if (!name || !ArrayExtensions.hasElements(attributes)) {
        return undefined;
    }

    const attribute = attributes?.find(x => x.KeyName?.toUpperCase() === name?.toUpperCase());

    return attribute;
};

export const getAttributeTextValueByName = (attributes: AttributeValue[], name: string): string => {
    let textValue = '';

    if (!name || !ArrayExtensions.hasElements(attributes)) {
        return textValue;
    }

    const attribute = getAttributeByName(attributes, name) as AttributeValue;
    if (attribute && attribute.TextValue) {
        textValue = attribute.TextValue;
    }

    return textValue;
};

export function getIntensity(product: ProductSearchResult): number | undefined {
    if (!product?.AttributeValues?.length) {
        return undefined;
    }

    const intensityAttributeValue = getAttributeTextValueByName(product.AttributeValues, INTENCITY_ATTRIBUTE_NAME);

    const intensityValue = parseInt(intensityAttributeValue, 10);

    return !isNaN(intensityValue) ? intensityValue : undefined;
}

export function getFormat(product: ProductSearchResult): number | undefined {
    if (!product?.AttributeValues?.length) {
        return undefined;
    }

    const formatAttributeValue = getAttributeTextValueByName(product.AttributeValues, FORMAT_ATTRIBUTE_NAME); //data.product.AttributeValues.find(x => x.KeyName?.toUpperCase() === 'INTENSITY');

    const formatValue = parseInt(formatAttributeValue, 10);

    return !isNaN(formatValue) ? formatValue : undefined;
}
export function getShortName(product: ProductSearchResult): string | undefined {
    if (!product?.AttributeValues?.length) {
        return undefined;
    }
    const productShortName = getAttributeTextValueByName(product.AttributeValues, SHORT_ATTRIBUTE_NAME);
    if (!productShortName) {
        return product.Name;
    }
    return productShortName;
}

export function getCupSize(product: ProductSearchResult): string | undefined {
    if (!product?.AttributeValues?.length) {
        return undefined;
    }
    const cupSize = getAttributeTextValueByName(product.AttributeValues, CUPSIZE_ATTRIBUTE_NAME);
    // if (!cupSize) {
    //     return product.DefaultUnitOfMeasure;
    // }
    return cupSize;
}

export function getMaxIntensity(product: ProductSearchResult): string {
    if (!product?.AttributeValues?.length) {
        return '';
    }
    const maxIntensity = getAttributeTextValueByName(product.AttributeValues, MAXINTENSITY_ATTRIBUTE_NAME);
    return maxIntensity;
}

export function isCapsuleRequired(product: ProductSearchResult): string | undefined {
    if (!product?.AttributeValues?.length) {
        return undefined;
    }
    const capsuleValue = getAttributeTextValueByName(product.AttributeValues, ISCAPSULEREQUIRE_ATTRIBUTE_NAME);
    return capsuleValue;
}

export function isIntensityRequired(product: ProductSearchResult): string | undefined {
    if (!product?.AttributeValues?.length) {
        return undefined;
    }
    const intensityValue = getAttributeTextValueByName(product.AttributeValues, ISINTENSITYREQUIRE_ATTRIBUTE_NAME);
    return intensityValue;
}
