import React from 'react';
import { CapsuleIcon } from './capsule-icon';

interface IProductUnitQuantityProps {
    productUnitQuantity: number | undefined;
    capsuleCriteria: string | undefined;
}
const ProductUnitQuantityDisplay: React.FC<IProductUnitQuantityProps> = ({ productUnitQuantity, capsuleCriteria }) => {
    if (capsuleCriteria === '1') {
        return (
            <div className='capsule-quantity-container'>
                <CapsuleIcon className='capsule-quantity-item' />
                <span className='capsule-quantity-item'>x</span>
                <span className='capsule-quantity-item'>{productUnitQuantity}</span>
            </div>
        );
    }
    return null;
};

export default ProductUnitQuantityDisplay;
