import * as React from 'react';

export const CapsuleIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                id='Vector'
                d='M0.000129901 11.6928C-0.00314508 12.4109 0.895451 12.9888 2.669 13.4109C4.22784 13.7824 6.29537 13.9912 8.49073 13.9997C10.6861 14.0083 12.7552 13.8144 14.3173 13.4553C16.0948 13.0464 16.9966 12.4748 17 11.7567C17.0024 11.1983 16.4577 10.7255 15.383 10.3439L14.0085 1.92776L13.9905 1.85923C13.9397 1.72215 13.5301 0.810137 11.5313 0.316348C10.7818 0.130984 9.80945 0.00481257 8.5487 0.000147081C3.92626 -0.0177662 3.16357 1.63025 3.09227 1.81795L3.07999 1.85066L1.62846 10.2917C0.550465 10.6655 0.00258471 11.1344 0.000129901 11.6928ZM3.8492 2.05868C3.89016 1.99092 4.06299 1.7417 4.52746 1.48312C5.13362 1.14666 6.32221 0.7479 8.5445 0.756474C12.3256 0.770492 13.1359 1.93486 13.2292 2.09373L14.5358 10.0899C14.5046 10.0822 14.4752 10.0736 14.4432 10.0658C14.4055 10.0565 14.3678 10.0471 14.3302 10.0385C12.7713 9.66781 10.7038 9.4583 8.50843 9.44975C6.31307 9.44118 4.24395 9.63511 2.68188 9.99416C2.61062 10.0105 2.54427 10.0276 2.47628 10.044L3.8492 2.05868ZM8.50604 10.206C13.4913 10.2247 16.2069 11.238 16.2044 11.7536C16.2036 11.8899 15.83 12.3283 14.1295 12.7201C12.628 13.0659 10.626 13.2513 8.49293 13.2435C6.35985 13.2357 4.35951 13.034 2.8614 12.6773C2.82536 12.6687 2.79014 12.6602 2.75491 12.6516C1.14856 12.2544 0.793869 11.8284 0.794686 11.6952C0.797144 11.1804 3.52081 10.1873 8.50604 10.206Z'
                fill='#808080'
            />
        </svg>
    );
};
